import { navigate } from '@reach/router';
import React, { useRef } from 'react';
import styles from './index.module.css';

function Home(props) {
  const onCheck = () => {
    fetch(`https://api.digma.io/api/plugins/form/${idRef.current.value}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 500) {
          alert('Form ID not found');
        } else {
          navigate(`/${idRef.current.value}`);
        }
      });
  };

  const idRef = useRef();
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <img
          src={require('../../assets/digma-forms.png').default}
          alt="Digma Forms"
        />
        <input ref={idRef} type="text" placeholder="#######" />
        <small>Please enter your 7 character reference</small>
        <div className={styles.button} onClick={onCheck}>
          Access Form
        </div>
      </div>
    </div>
  );
}

export default Home;
