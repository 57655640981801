import React, { useRef } from 'react';
import styles from './index.module.css';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import Form from '@bit/digma.plugins.form';

function Home(props) {
  const { formId } = props;
  const location = useLocation();
  const searchParams = parse(location.search);
  const containerRef = useRef(null);

  const sendResizeMessage = (size) => {
    const { width, height } = size;
    console.log(size);
    window.top.postMessage(
      {
        type: 'resize',
        width,
        height,
      },
      '*'
    );
  };

  if (searchParams.embed) {
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Form
          data={{ formId: formId }}
          onResize={(size) => {
            sendResizeMessage(size);
          }}
          onSubmit={(responseObj) => {
            fetch('https://core.digma.io/FormResponse', {
              method: 'post',
              body: JSON.stringify(responseObj),
            }).catch((e) => {
              console.log(e);
            });
          }}
        />
      </div>
    );
  }
  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.inner}>
        <Form
          data={{ formId: formId }}
          onSubmit={(responseObj) => {
            fetch('https://core.digma.io/FormResponse', {
              method: 'post',
              body: JSON.stringify(responseObj),
            }).catch((e) => {
              console.log(e);
            });
          }}
        />
      </div>
    </div>
  );
}

export default Home;
