import React, { Suspense } from 'react';
import { Router } from '@reach/router';
import Home from './pages/home';
import Forms from './pages/forms';
import './App.css';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router style={{ height: '100%' }}>
        <Home path="/" />
        <Forms path="/:formId" />
      </Router>
    </Suspense>
  );
}

export default App;
